<template>
  <div class="cart">
    <div v-if="orderData">
      <div :class="'orderShowt'" style="display:block">
          <v-order :order-data="newCarts[0]" :configure="configure" :type="true"></v-order>
      </div>
      <div :class="'orderShowb'" style="display:none">
          <v-order v-for="(child,childindex) in newCarts" :key="childindex" :order-data="child" :configure="configure" :type="true"></v-order>
          <div style="margin-bottom:5px;">
              <div class="awardGoods" v-if="settlement.awardGoods">
                  <van-row>
                      <van-col span="10" class="awardname">平臺贈送獎勵</van-col>
                      <van-col span="14" v-if="settlement.awardGoods.type == '0'" class="awardname" style="text-align: right">
                        {{settlement.awardGoods.prizeName}}<span v-if="settlement.awardGoods.num > 1">x{{settlement.awardGoods.num}}</span> {{settlement.awardGoods.showUnit}}</van-col>
                      <van-col span="14" v-if="settlement.awardGoods.type == '1'" class="awardname voucher" style="text-align: right">訂單立減￡{{Math.abs(settlement.awardGoods.unitPrice)}}</van-col>
                  </van-row>
                  <van-row>
                      <van-col span="10" class="awardname" >Free Prize</van-col>
                      <van-col span="14" v-if="settlement.awardGoods.type == '0'" class="awardname" style="text-align: right">
                        {{settlement.awardGoods.prizeNameEn}}
                        <span v-if="settlement.awardGoods.num > 1">x{{settlement.awardGoods.num}}</span>
                         {{settlement.awardGoods.showUnit}}
                        </van-col>
                      <van-col span="14" v-if="settlement.awardGoods.type == '1'"  class="awardname voucher" style="text-align: right">Order reduction￡{{ Math.abs(settlement.awardGoods.unitPrice)}}</van-col>
                  </van-row>
              </div>
              <div class="awardGoods" v-if="settlement.giftGoods">
                  <van-row>
                      <van-col span="10" class="awardname">平臺贈送禮物</van-col>
                      <van-col span="14" class="awardname" style="text-align: right">
                          {{settlement.giftGoods.giftName}}
                          <span v-if="settlement.giftGoods.giftNum > 1">x{{settlement.giftGoods.giftNum}}</span>
                          {{settlement.giftGoods.giftShowUnit}}
                      </van-col>
                  </van-row>
                  <van-row>
                      <van-col span="10" class="awardname" >Free Gift</van-col>
                      <van-col span="14"  class="awardname" style="text-align: right">
                          {{settlement.giftGoods.giftNameEn}}
                          <span v-if="settlement.giftGoods.giftNum > 1">x{{settlement.giftGoods.giftNum}}</span> 
                          {{settlement.giftGoods.giftShowUnit}}
                      </van-col>
                  </van-row>
              </div>
          </div>
      </div>
      <div style="text-align:center;">
          <van-button  size="large" style="width:93%;" @click="showMore('orderShow')">{{showButton ? 'Show more':'Put away'}}</van-button>
      </div>
    </div>
    <div class="cart_main" v-show="settlement.totalInAmount > 0" >
        <van-row  class="bottom_detail">
            <van-row class="foot_line_height"> 
                <van-col span="24" >商用<span style="font-size:14px;"> (For Commercial)</span></van-col>
            </van-row>
            <van-row class="foot_line_height">
                <van-col span="14" >商品金額<span style="font-size:14px;"> (Total)</span></van-col>
                <van-col span="10" class="text-right text-green"><span class="symbol">￡</span>{{settlement.totalInAmount}} </van-col>
            </van-row>
            <van-row class="foot_line_height" v-if="settlement.mainOrder == null">
                <van-col span="14" >優惠金額<span style="font-size:14px;"> (Voucher)</span></van-col>
                <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{indata.discountTotalInBonusAmount}}</van-col>
            </van-row>
            <!-- <van-row class="foot_line_height">
                <van-col span="14" >錢包抵扣<span style="font-size:14px;"> (Wallet)</span></van-col>
                <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{indata.discountWalletOffsetInAmount}}</van-col>
            </van-row> -->
            <van-row class="foot_line_height">
                <van-col span="14" >折扣金額<span style="font-size:14px;"> (Discount)</span></van-col>
                <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{indata.discountTotalInOffAmount}}<span style="font-size:14px"> ({{Math.floor((1-settlement.discountPercent)*100)}}%off)</span></van-col>
            </van-row>
            <van-row class="foot_line_height">
                <van-col span="14" >VAT </van-col>
                <van-col span="10" class="text-right text-green"><span class="symbol">￡</span>{{indata.discountTotalInTaxAmount}} </van-col>
            </van-row>
            <van-row class="foot_line_height">
                <van-col span="14" >商用需支付金額<span style="font-size:14px;">(Grand total)</span></van-col>
                <van-col span="10" class="text-right text-green"> <span class="symbol">￡</span>{{indata.discountTotalInAmountWithTax}} </van-col>
            </van-row>
        </van-row>
        <div style="display: flex;justify-content: start;" class="bottom_detail">
          <van-radio-group v-model="inPaymentType" direction="horizontal" @change="paymentMeth">
            <!-- <van-radio name="ONLINE" value="ONLINE"><span style="font-size:16px;">即時在線付款</span><br/><span style="font-size:12px;">Pay Online</span></van-radio> -->
            <van-radio name="OFFLINE" value="OFFLINE"><span style="font-size:16px;">货到付款</span><br/><span style="font-size:12px;">Pay On Delivery</span></van-radio>
          </van-radio-group>
        </div>
    </div>
    <div class="cart_main" v-show="settlement.totalOutAmount > 0">
        <van-row  class="bottom_detail" >
            <van-row class="foot_line_height">
                <van-col span="24" >自用<span style="font-size:14px;"> (For Domestic) </span></van-col>
            </van-row>
            <van-row class="foot_line_height">
                <van-col span="14" >商品金額<span style="font-size:14px;"> (Total)</span></van-col>
                <van-col span="10" class="text-right text-green"><span class="symbol">￡</span>{{settlement.totalOutAmount}} </van-col>
            </van-row>
            <van-row class="foot_line_height" v-if="settlement.mainOrder == null">
                <van-col span="14" >優惠金額<span style="font-size:14px;"> (Voucher)</span></van-col>
                <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{outdata.discountTotalOutBonusAmount}}</van-col>
            </van-row>
            <!-- <van-row class="foot_line_height">
                <van-col span="14" >錢包抵扣<span style="font-size:14px;"> (Wallet)</span></van-col>
                <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{outdata.discountWalletOffsetOutAmount}}</van-col>
            </van-row> -->
            <van-row class="foot_line_height">
                <van-col span="14" >折扣金額<span style="font-size:14px;"> (Discount)</span></van-col>
                <van-col span="10" class="text-right text-orange"><span class="symbol">￡</span>{{outdata.discountTotalOutOffAmount}}<span style="font-size:14px"> ({{Math.floor((1-settlement.discountPercent)*100)}}%off)</span></van-col>
            </van-row>
            <van-row class="foot_line_height">
                <van-col span="14" >VAT </van-col>
                <van-col span="10" class="text-right text-green"><span class="symbol">￡</span>{{outdata.discountTotalOutTaxAmount}} </van-col>
            </van-row>
            <van-row class="foot_line_height">
                <van-col span="14" >自用需支付金額<span style="font-size:14px;"> (Grand total)</span></van-col>
                <van-col span="10" class="text-right text-green"> <span class="symbol">￡</span>{{outdata.discountTotalOutAmountWithTax}} </van-col>
            </van-row>
        </van-row>
        <div style="display: flex;justify-content: start;" class="bottom_detail">
          <van-radio-group v-model="outPaymentType" direction="horizontal" @change="paymentMeth">
            <!-- <van-radio name="ONLINE" value="ONLINE">在线付款<br/><span style="font-size:12px;">Pay Online</span></van-radio> -->
            <van-radio name="OFFLINE" value="OFFLINE">货到付款<br/><span style="font-size:12px;">Pay On Delivery</span></van-radio>
          </van-radio-group>
        </div>
    </div>
    <div class="remark" v-if="carts.length > 0">
        <van-row class="bottom_detail">
          <van-col span="8">
            送貨信息:
          </van-col>
          <van-col span="16">
            <select v-model="remark" :style="{border: remark ? 'none' : '2px solid #E59700'}" style="border-radius: 4px;background: transparent;width:100%;height:30px;" :disabled="orderObject != null || settlement.mainOrder && settlement.mainOrder.orderNum != orderData.subOrderNum ? true : false" :class="[orderObject != null || settlement.mainOrder && settlement.mainOrder.orderNum != orderData.subOrderNum ? 'not-arrow': '']">
              <option value=""></option>
              <option value="15">货到前15分钟致电给我</option>
              <option value="30">货到前30分钟致电给我</option>
              <option value="45">货到前45分钟致电给我</option>
              <option value="60">货到前60分钟致电给我</option>
            </select>
          </van-col>
          <van-col span="24"><p style="font-size:14px;">Delivery Instruction：<span v-if="remark != ''">Please call before {{remark}} mins </span></p> </van-col>
        </van-row>
    </div>
    <div class="remark" style="margin-bottom:220px;"  v-if="carts.length > 0">
        <van-row class="bottom_detail">
          <van-col span="8">
            送貨時間:
          </van-col>
          <van-col span="16">
            <select v-model="deliveryDate" :style="{border: deliveryDate ? 'none' : '2px solid #E59700'}"  style="border-radius: 4px;background: transparent;width:100%;height:30px;" :disabled="orderObject != null || settlement.mainOrder && settlement.mainOrder.orderNum != orderData.subOrderNum ? true : false" :class="[orderObject != null || settlement.mainOrder && settlement.mainOrder.orderNum != orderData.subOrderNum ? 'not-arrow': '']">
              <option v-for="(item,index) in timeList" :value="item.optionDate" :key="index">{{item.date}}</option>
            </select>
          </van-col> 
          <van-col span="24"><p style="font-size:14px;">Delivery Date</p></van-col>
        </van-row>
    </div>
    <div class="foot_main" style="min-height:50px;line-height:20px;background:#fff;">
        <van-row type="flex" justify="space-around" style="background:#fff7cc;padding:5px 15px;">
          <van-col span="24" style="font-size:12px;color:#f56723;">截单时间为晚上十一时, 之后的订单会下一个工作天计算<br/>The cut-off time is 11pm, orders will be counted on the following working day</van-col>
        </van-row>
        <van-row style="padding:0 15px;">
          <van-col style="font-size:14px" span="18">
            <van-col span="24">總金額<span>(Grand total)</span>
              <span style="margin-right:85px;">
                <span style="font-size:12px;color:#72B950;">￡</span>
                <span style="color:#72B950;">{{settlement.paymentPre ? settlement.paymentPre.orderTotalAmount: ''}}</span>
              </span>
            </van-col>
            <van-col span="24">需支付綜金額<span>(Required payment)</span>
              <span style="margin-right:85px;">
                <span style="font-size:12px;color:#72B950;">￡</span>
                <span style="color:#72B950;">{{settlement.paymentPre ? settlement.paymentPre.orderRealityAmount : ''}}</span>
              </span>
            </van-col>
            <van-col span="24">在綫支付金額<span>(Online total)</span>
              <span style="margin-right:85px;">
                <span style="font-size:12px;color:#72B950;">￡</span>
                <span style="color:#72B950;">{{settlement.paymentPre ? settlement.paymentPre.onlinePayment.discountDealAmount : ''}}</span>
              </span>
            </van-col>
          </van-col>
          <van-col style="font-size:14px;text-align:right;" span="6">
            <van-button type="info" class="submit" style="border:none;" @click="onSubmit">支付<br/>Payment</van-button>
          </van-col>
        </van-row>
    </div>  
    <van-popup v-model="payStatus" position="bottom" :style="{ height: '60%' }">
      <div class="content">
        <stripe :payData="payData"></stripe> 
      </div>
    </van-popup>
  </div>
</template>
<script>
import {goodsOrder,getLimit,getCartprice,payForOrder,getOrderInfo,getDeliveryTime} from  '@/api/cart'
import { Toast,Dialog,Notify } from "vant";
import stripe from '../stripe/index'
import vOrder from "@/components/order"
export default {
  name: 'cart',
  computed: {
    
  },
  components:{
    stripe,
    vOrder
  },
  data() {
    return {
        indata:{
          discountTotalInAmount:'',
          discountTotalInAmountWithTax:'',
          discountTotalInBonusAmount:"",
          discountTotalInOffAmount:'',
          discountTotalInTaxAmount:'',
          discountWalletOffsetInAmount:''
        },
        outdata:{
          discountTotalOutAmount:"",
          discountTotalOutAmountWithTax:"",
          discountTotalOutBonusAmount:"",
          discountTotalOutOffAmount:"",
          discountTotalOutTaxAmount:"",
          discountWalletOffsetOutAmount:""
        },
        remark:'',
        carts:[],
        payData:{},
        payStatus:false,
        orderData:null,
        settlement:[],
        configure: {
        },
        newCarts:[],
        showButton:true,
        mainorderNum:'',
        // replenishStatus:false,
        orderRadio:'',
        inPaymentType:"OFFLINE",
        outPaymentType:"OFFLINE",
        orderObject:null,
        timeList:[],
        deliveryDate:''
    }
  },
  created() {
    this.orderObject = JSON.parse(localStorage.getItem('orderObject')) ;
    if(this.orderObject) {
      this.remark = this.orderObject.remark;
      let date = this.formatWeek(this.orderObject.sendTime)
      this.deliveryDate = date.substring(0,10)
    }
    this.getDeliveryTime()
    this.orderData = this.$route.query.orderData != undefined ? JSON.parse(this.$route.query.orderData) : null;
    if(this.orderData) {
      this.inPaymentType = this.orderData.paymentMethodIn == '1' ? 'ONLINE' :'OFFLINE'
      this.outPaymentType = this.orderData.paymentMethodOut == '1' ? 'ONLINE': 'OFFLINE'
      this.payOrder()
    }else {
      this.getCartprice()
    }
  },
  methods: {
    getCartprice() {
      let params = {
        "inPaymentType": this.inPaymentType,
        "outPaymentType": this.outPaymentType,
        "type": this.orderObject ? 'ADD' : 'NEW'
      }
      getCartprice(params).then(res => {
        if(res.code == 200){
          this.carts = res.data.userCartCategory;
          this.settlement = JSON.parse(JSON.stringify(res.data));
          this.mapCalculate()
          this.dataReconstruct(this.carts)
        }else {
          Dialog.confirm({
            title: '提示',
            message: '購物車數據異常，請返回購物車處理',
            confirmButtonText:'返回購物車',
            showCancelButton:false
          }).then(() => {
              this.$router.push('/cart')
          })
        }
      })
    },
    payOrder() {
      let parmas = {
        inPaymentType: this.inPaymentType,
        outPaymentType: this.outPaymentType,
        subOrderNum: this.orderData.subOrderNum
      }
      getOrderInfo(parmas).then(res => {
        this.carts = res.data.userCartCategory;
        this.settlement = JSON.parse(JSON.stringify(res.data));
        this.remark = this.settlement.mainOrder ? this.settlement.mainOrder.remark : this.remark;
        this.deliveryDate = this.settlement.mainOrder ? this.settlement.mainOrder.deliveryDate : this.deliveryDate;
        this.mapCalculate()
        this.dataReconstruct(this.carts)
      })
    },
    mapCalculate() {
      this.indata = {
          discountTotalInAmount:this.settlement.discountTotalInAmount,
          discountTotalInAmountWithTax: this.settlement.discountTotalInAmountWithTax,
          discountTotalInBonusAmount: this.settlement.discountTotalInBonusAmount,
          discountTotalInOffAmount: this.settlement.discountTotalInOffAmount,
          discountTotalInTaxAmount: this.settlement.discountTotalInTaxAmount,
          discountWalletOffsetInAmount: this.settlement.discountWalletOffsetInAmount
      }
      this.outdata = {
        discountTotalOutAmount: this.settlement.discountTotalOutAmount,
        discountTotalOutAmountWithTax: this.settlement.discountTotalOutAmountWithTax,
        discountTotalOutBonusAmount: this.settlement.discountTotalOutBonusAmount,
        discountTotalOutOffAmount: this.settlement.discountTotalOutOffAmount,
        discountTotalOutTaxAmount: this.settlement.discountTotalOutTaxAmount,
        discountWalletOffsetOutAmount: this.settlement.discountWalletOffsetOutAmount
      }
      this.totalGoodsAmount = this.settlement.totalGoodsAmount - this.settlement.discountOffAmount - this.settlement.discountBonusAmount;
    },
    onSubmit() {
      if(this.deliveryDate == '') {
        Notify('送貨時間不能爲空');
        return false;
      }else {
        if(this.orderData) {
          let payType = this.orderObject ? false : true;
          let params = {
              "deliveryDate": this.deliveryDate,
              "orderNum": this.settlement.mainOrder ? this.settlement.mainOrder.orderNum : this.mainorderNum,
              "inPaymentType": this.inPaymentType,
              "outPaymentType": this.outPaymentType,
              "remark": this.settlement.mainOrder ? this.settlement.mainOrder.remark : this.remark,
              "subOrderNum": this.orderData.subOrderNum
          }
          payForOrder(params).then(res => {
              if(res.code == 200) {
                if((this.inPaymentType == 'ONLINE' || this.outPaymentType == 'ONLINE') &&  this.settlement.paymentPre.onlinePayment.discountDealAmount > 0 && res.data.orderStatus == 0) {
                  this.payStatus = true;
                  this.payData = res.data.paymentPre.onlinePayment;
                  this.payData.orderType = payType;
                  this.payData.totalGoodsAmount = this.totalGoodsAmount;
                  this.payData.orderNum = res.data.orderNum
                  this.payData.subOrderNum = this.orderData.subOrderNum
                }else {
                  if(payType) {
                    this.$router.push({path:'/turntable',query:{price: this.totalGoodsAmount,orderId: this.orderData.subOrderNum}})
                  }else {
                    this.$router.push({path:'/ordersuccess'})
                  }
                  localStorage.setItem('orderObject',null)
                }
                // this.orderObject = null
                // localStorage.setItem('orderObject',null)
              }else {
                Notify({ type: 'danger', message: res.msg });
              }  
          })
        }else {
          let params = {
            deliveryDate: this.deliveryDate,
            inPaymentType:this.inPaymentType,
            outPaymentType:this.outPaymentType,
            orderNum: this.orderObject ? this.orderObject.recentOrder.orderNum : '',
            remark: this.remark
          }
          goodsOrder(params).then(res => {
            if(res.code == 200) {
              let payType = this.orderObject ? false : true
              if((this.inPaymentType == 'ONLINE' || this.outPaymentType == 'ONLINE') && this.settlement.paymentPre.onlinePayment.discountDealAmount > 0 && res.data.orderStatus == 0) {
                this.payStatus = true;
                this.payData = res.data.paymentPre.onlinePayment;
                this.payData.orderType = payType;
                this.payData.totalGoodsAmount = this.totalGoodsAmount;
                this.payData.orderNum = res.data.orderNum;
                this.payData.subOrderNum = res.data.subOrderNum;
                this.mainorderNum = res.data.orderNum;
                this.orderData = {
                  subOrderNum:res.data.subOrderNum
                }
              }else {
                if(payType) {
                  this.$router.push({path:'/turntable',query:{price: this.totalGoodsAmount,orderId: res.data.orderNum}})
                }else {
                  this.$router.push({path:'/ordersuccess'})
                }
                localStorage.setItem('orderObject',null)
              }
              // this.orderObject = null
              // localStorage.setItem('orderObject',null)
            }else if(res.code == 10001){
              Dialog.confirm({
                title: '提示',
                message: '購物車數據異常，請返回購物車處理',
                confirmButtonText:'返回購物車'
              }).then(() => {
                  this.$router.push('/cart')
              })
            }else {
              Notify({ type: 'danger', message: res.msg });
            }
          })
        }

      }
    },
    showMore(name) {
        if(document.getElementsByClassName(name + 't')[0].style.display == 'block') {
            document.getElementsByClassName(name + 't')[0].style.display = 'none';
            this.showButton = true;
        }else {
            document.getElementsByClassName(name + 't')[0].style.display = 'block';
            this.showButton = false;
        }

        if(document.getElementsByClassName(name + 'b')[0].style.display == 'block') {
            document.getElementsByClassName(name + 'b')[0].style.display = 'none';
            this.showButton = true;
        }else {
            document.getElementsByClassName(name + 'b')[0].style.display = 'block';
            this.showButton = false;
        }
    },
    dataReconstruct(list) {
      this.newCarts = [];
      list.map(item => {
        item.userCartGoodsDetail.map(t => {
          t.goodsName = t.name
          t.goodsNameEn = t.nameEn
          t.vat = t.tax ? 1 : 0
          t.goodsCover = t.cover
          t.qty = t.num
          t.qtyIn = t.inNum
          t.qtyOut = t.outNum
        })
        this.newCarts = this.newCarts.concat(item.userCartGoodsDetail)
      })
    },
    paymentMeth() {
      if(this.orderData) {
        this.payOrder()
      }else {
        this.getCartprice()
      }
    },
    getDeliveryTime() {
      getDeliveryTime().then(res => {
        this.timeList = this.dateReconstruct(res.data)
      })
    },
    dateReconstruct(data) {
      let list = [];
      const weekAry = ['Mon','Tue','Wed','Thur','Fri','Sat','Sun']
      data.map(item => {
        let date = [],str = '';
        date =  item.optionDate.split('-');
        str = date[0] + '/' + date[1] + '/' + date[2] +'/' + ' (' + weekAry[item.week-1] + ')'
        list.push({optionDate:item.optionDate,date:str})
      })
      return list;
    },
  }
}
</script>

<style lang="scss" scoped>
.cart_main {
    width: 93%;
    margin: 30px auto;
    background: #fff;
    border-radius: 20px;
}
.foot_main {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.submit-bar{
  height: 50px;
}
.text-right {
    text-align: right;
}
.foot_line_height {
    min-height: 60px;
    line-height: 60px;
}
.symbol {
    font-size:14px;
}
.text-green {
    color: #72B950;
}
.text-orange {
    color: #FF8E29;
}
.text-grey {
  color: #919191;
}
.bottom_detail {
  padding: 8px 30px;
  font-size: 18px;
  height: 100%;
  width: 100%;
}
.submit  {
    background: linear-gradient(90deg, #64B046, #AEE845);
    color: #fff;
    border-radius: 10px;
}
.remark {
  width: 93%;
  margin: 20px auto;
  background: #fff;
  border-radius: 20px;
}
.payment {
  height: 80px;
}

.remark_btn {
  width: 93%;
  margin: 20px auto;
  border-radius: 20px;
  background: #fff;
}
.content  {
  padding: 50px 20px;
}
.awardGoods{
  width: 93%;
  margin: auto;
  border-radius: 15px;
  padding: 0 20px;
  background: #fff;
  margin-top: 15px;
}
.awardname {
  min-height: 50px;
  line-height: 50px;
  font-size: 14px;
}
.not-arrow {
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
}
</style>
